import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private authService: AuthService,
    private menu: MenuController,
    public userService: UserService
  ) {}

  toggleMenu() {
    this.menu.toggle();
  }

  logout() {
    this.toggleMenu();
    this.authService.logout();
  }

}
